import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";
import styles from "../style/pages/book.module.scss";

const Page = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const books = data.allMarkdownRemark.edges;

  return (
    <Layout title={siteTitle}>
      <SEO title="Books" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template">
        <div className="post-content-body">
          <div className={styles.grid}>
            {books.map((book) => {
              let { cover } = book.node.frontmatter;
              return (
                <div className={styles.bookContainer}>
                  <Link to={book.node.fields.slug}>
                    <img src={cover} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/book/" } }
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC] }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            title
            cover
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => <Page location={props.location} data={data} {...props} />}
  />
);
